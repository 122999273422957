import React, { Component } from "react";
import { apiAddDomain } from "../../util/api";
import { withAppContext } from "../withAppContext";
import { injectIntl, FormattedMessage } from "react-intl";

/**
 * @typedef {typeof initialState} State
 */
const initialState = Object.freeze({
    itemValue: "",
    hasError: false,
});

class Create extends Component {
    state = initialState;

    /**
     * @param {import('react').FormEvent} event
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.itemValue.length > 4) {
            const { itemValue } = this.state;
            const domainObject = { domain: itemValue, block: this.props.block };

            const newItem = await apiAddDomain(domainObject);

            if (Boolean(newItem)) {
                this.props.addItem(newItem);
                this.setState(initialState);
                return;
            }
        }

        this.setState({ hasError: true });
    };

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(() => ({ [name]: value, hasError: null }));
    };

    render() {
        const { itemValue } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            <form onSubmit={this.handleSubmit}>
                <div
                    className={`form-group${this.state.hasError ? " has-error" : ""}`}
                >
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-input"
                            name="itemValue"
                            value={itemValue}
                            placeholder={formatMessage({
                                id: "filterlist.add_domain",
                            })}
                            onChange={this.handleChange}
                        />
                        <button className="btn btn-primary input-group-btn">
                            <i className="icon icon-plus" />
                        </button>
                    </div>
                    <p className="form-input-hint">
                        {this.state.hasError && (
                            <FormattedMessage
                                id="filterlist.error_exists"
                                values={{ domain: itemValue }}
                            />
                        )}
                    </p>
                </div>
            </form>
        );
    }
}

const CreateItem = withAppContext(injectIntl(Create));

export { CreateItem };
