import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

const isValidDomain = (domain) => {
    const parts = domain.split(".");
    for (const part of parts) {
        if (part === "*") continue;
        if (/[\[\](){},?/:!@#$%^&*+='"`|\\<>;]/.test(part)) return false;
    }

    return true;
}

const patternMatch = (pattern, target) => {
    for (let i = 0; i < pattern.length; i++) {
        if (pattern[i] === "*") continue;
        if (pattern[i] !== target[i]) return false;
    }
    return true;
};

const Item = (props) => {
    const { formatMessage } = props.intl;
    /**@type {{ portals: API.PortalObject[] }}*/
    const { portals } = props;

    const [item, setItem] = useState(props.item);
    const [isEditing, setIsEditing] = useState(false);

    const initialValue = props.item.domain;

    const [foundPortal, setFoundPortal] = useState(null);
    const [invalidDomain, setInvalidDomain] = useState(false);

    useEffect(() => {
        setInvalidDomain(false);
            if (isEditing) return;

        if (!isValidDomain(item.domain)) {
            setInvalidDomain(true);
        }
        console.log({ invalidDomain });
    }, [isEditing, setInvalidDomain])

    useEffect(() => {
        setFoundPortal(null);
        if (isEditing) return;

        const parts = item.domain.split(".");

        let portal = null;
        let pattern;

        for (const p of portals) {
            for (const domain of p.domains.split(",")) {
                const sParts = domain.split(".");

                if (parts.length < sParts.length) {
                    // detect `parts=google.com` and `sParts=*.google.com`
                    if (
                        sParts[0] === "*" &&
                        sParts.length - parts.length === 1
                    ) {
                        parts.unshift("*");
                    } else {
                        continue;
                    }
                }

                if (patternMatch(sParts, parts)) {
                    portal = p;
                    pattern = domain;
                    break;
                }
            }
        }

        if (portal) {
            setFoundPortal({ portal, pattern });
        }
    }, [isEditing, setFoundPortal, portals]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setItem({ ...item, [name]: value });
    };

    const handleCheckedChange = (event) => {
        const { name, value } = event.target;
        setItem({ ...item, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsEditing(false);
        props.updateItem(item.id, item, initialValue);
    };

    return (
        <>
            <tr>
                <td>
                    <input
                        type="checkbox"
                        name={item.id}
                        id={item.id}
                        onChange={handleCheckedChange}
                    />
                </td>
                <td>
                    {isEditing ? (
                        <>
                            <form method="post" onSubmit={handleSubmit}>
                                <input
                                    className="form-input"
                                    type="text"
                                    name="domain"
                                    value={item.domain}
                                    onChange={handleInputChange}
                                />
                            </form>
                        </>
                    ) : (
                        <>
                            {foundPortal ? (
                                <div className="popover">
                                    <span
                                        className="bg-error"
                                        style={{
                                            cursor: `pointer`,
                                            padding: "0.5em",
                                        }}
                                    >
                                        <i
                                            className="icon icon-flag"
                                            style={{ marginRight: "0.5em" }}
                                        ></i>
                                        {item.domain}
                                    </span>
                                      <div className="popover-container">
                                        <div className="card">
                                            <div className="card-header">
                                                {formatMessage({
                                                    id: "filterlist.managed_by_portals",
                                                })}
                                            </div>
                                            <div className="card-body">
                                                Portal:
                                                <code>
                                                    {foundPortal.portal.name}
                                                </code>
                                            </div>
                                            <div className="card-footer">
                                                Pattern:
                                                <code>
                                                    {foundPortal.pattern}
                                                </code>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : invalidDomain ? (
                              <div className="popover">
                                <span className="bg-warning" style={{ padding: "0.5em", cursor: "pointer", display: "inline-flex", alignItems: "center" }}>
                                    <i className="icon icon-flag" style={{ marginRight: "0.5em" }}></i>
                                  {item.domain}
                                </span>
                                  <div className="popover-container">
                                      <div className="card">
                                          <div className="card-header">
                                              Warning
                                          </div>
                                          <div className="card-body">
                                              {formatMessage({
                                                  id: "filterlist.invalid_domain",
                                              })}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            ) : (
                              item.domain
                            )}
                        </>
                    )}
                </td>
                <td style={{ textAlign: `right` }}>
                    {isEditing ? (
                        <>
                            <button
                                className="btn btn-primary btn-sm mr-2"
                                title={formatMessage({ id: "submit" })}
                                onClick={handleSubmit}
                            >
                                <i className="icon icon-check" />
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="btn btn-default btn-sm mr-2"
                                title={formatMessage({ id: "edit" })}
                                onClick={() => setIsEditing(true)}
                            >
                                <i className="icon icon-edit" />
                            </button>
                            <button
                                className="btn btn-error btn-sm"
                                title={formatMessage({ id: "delete" })}
                                onClick={() =>
                                    window.confirm(
                                        formatMessage({ id: "are-you-sure" }),
                                    ) && props.deleteItem(item.id)
                                }
                            >
                                <i className="icon icon-delete" />
                            </button>
                        </>
                    )}
                </td>
            </tr>
        </>
    );
};

export default injectIntl(Item);
