import React from "react";
import "./style.css";

export const Explainer = (props) => {
    return (
        <div
            className={
                "toast explainer" + (props.warning ? " toast-warning" : "")
            }
        >
            {props.children}
        </div>
    );
};
