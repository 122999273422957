import React from "react";
import DataTableItem from "./Item";
import { CreateItem } from "./Create-Item";
import { FormattedMessage } from "react-intl";

const ItemTable = (props) => (
    <>
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>
                        <FormattedMessage id="filterlist.number" />
                    </th>
                    <th>{props.itemTitle}</th>
                    <th style={{ textAlign: `right` }}>
                        <FormattedMessage id="filterlist.actions" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td
                        colSpan={3}
                        className={props.isLoading ? "loading" : ""}
                    >
                        {!props.isLoading && (
                            <CreateItem
                                addItem={props.addItem}
                                itemName={props.itemName}
                                block={props.block}
                            />
                        )}
                    </td>
                </tr>

                {props.items && props.items.length
                    ? props.items.map((item) => (
                          <DataTableItem
                              key={item.id}
                              item={item}
                              portals={props.portals}
                              updateItem={props.updateItem}
                              deleteItem={props.deleteItem}
                          />
                      ))
                    : null}
            </tbody>
        </table>
    </>
);

export default ItemTable;
